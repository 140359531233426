import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Grid, TextField} from '@material-ui/core';
import {Search, Delete, Add} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {Column} from '@molecules/Table/props';
import {useNavigate} from 'react-router-dom';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useSearchParams} from '@front-libs/core';
import {PopperSelectBoxButton} from '@molecules/Buttons/PopperSelectBoxButton';
import qs from 'qs';
import {NewRegistrationDialog} from '../../Settings/Place/Dialogs/NewRoomRegistrationDialog';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoom} from '@modules/hospital_places/types';
import {
  bulkDeleteHospitalRooms,
  bulkUpdateHospitalRooms,
  HospitalRoomsParams,
  useFetchHospitalRooms,
  useFetchHospitalWards,
} from '@modules/hospital_places/api';
import {useDebounceState} from '@front-libs/core';
import {createHospitalRoom, deleteHospitalRoom, updateHospitalRoom} from '@modules/hospital_wards/room_api';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useStyles} from '@Apps/Settings/Place/styles';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {hospitalWardsAtom} from '@Apps/Settings/Place/state';
import {useAtom} from 'jotai';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {RoomBulkEditationDialog} from './Dialogs/RoomBulkEditationDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {Table} from '@molecules/Table';
import {fetchHospitalWard} from '@modules/hospital_wards/api';
import {NewEditationDialog} from './Dialogs/NewRoomEditationDialog';
import {EditHospitalRoomProps} from './types';

const HospitalRoomColumn: React.FC<EditHospitalRoomProps> = ({hospitalRoom}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.title}>
      {hospitalRoom.name}
    </Grid>
  );
};

export const HospitalRooms = () => {
  const queryParams = useSearchParams();
  const [searchName, setSearchName] = useDebounceState<string | null>((queryParams.name as string) || null, 500);
  const navigate = useNavigate();

  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [page, setPage] = useState<number>(Number(queryParams.page) || 1);
  const [wardHashIds, setWardHashIds] = useAtom(hospitalWardsAtom);
  const pageSize = 20;

  const roomParams = useMemo(() => {
    const _p: HospitalRoomsParams = {
      page: page - 1,
      perPage: 20,
      order: orderKey ?? undefined,
    };
    if (searchName) _p.name = searchName;
    return _p;
  }, [orderKey, page, searchName]);

  const {myInfo} = useMyInfo();
  const queryRoom = useFetchHospitalRooms(myInfo.hospitalHashId, roomParams);
  const queryWard = useFetchHospitalWards(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
  });

  const totalPageRoom = useMemo(() => {
    return Math.ceil(queryRoom.totalCount / pageSize);
  }, [queryRoom.totalCount, pageSize]);

  const templateClasses = useSettingsContentTemplate();
  const classes = useStyles();

  const handleChangePage = useCallback(
    (_e: React.ChangeEvent<unknown>, p: number) => {
      const resultQuery = qs.stringify({...queryParams, page: p}, {arrayFormat: 'brackets'});
      navigate({search: resultQuery});
      setPage(p);
    },
    [navigate, queryParams]
  );

  const handleClickRegistration = async () => {
    const newHospitalRoom = await dialogHandler.open(NewRegistrationDialog, {});
    await createHospitalRoom(myInfo.hospitalHashId, newHospitalRoom);
    await queryRoom.refetch();
  };

  const handleOrderChange = (columnIndex: number, orderDirection: 'asc' | 'desc') => {
    if (columnIndex === -1) {
      setOrderKey(null);
    } else {
      setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(serializedTableColumn[columnIndex].field)}`);
    }
  };

  const handleBulkDeleteRooms = useCallback(
    async (e: unknown, values: HospitalRoom[]) => {
      await dialogHandler.open(AlertDialog, {
        title: `${values.length}件の小エリアを削除しますか？`,
        content: `${values.length}件の小エリアを削除しようとしています。小エリアを削除すると元に戻せません。`,
        positiveButtonLabel: '小エリアを削除',
      });
      await bulkDeleteHospitalRooms(
        myInfo.hospitalHashId,
        values.map((room) => room.hashId)
      );
      await queryRoom.refetch();
      openSnackBar('小エリアを削除しました');
    },
    [myInfo.hospitalHashId, queryRoom]
  );

  const handleBulkUpdateRooms = useCallback(
    async (_: unknown, values: HospitalRoom[]) => {
      const res = await dialogHandler.open(RoomBulkEditationDialog, {
        count: values.length,
      });
      await bulkUpdateHospitalRooms(
        myInfo.hospitalHashId,
        values.map((room) => {
          return {hashId: room.hashId, hospitalWardHashId: res.hospitalWardHashId};
        })
      );
      await queryRoom.refetch();
      openSnackBar('小エリアを更新しました');
    },
    [myInfo.hospitalHashId, queryRoom]
  );

  const [tableLayout] = useTableLayout('hospitalRoomList');

  const roomData: HospitalRoom[] = queryRoom.data.filter((item) => {
    return wardHashIds.length === 0 || wardHashIds.includes(item.hospitalWard?.hashId ?? '');
  });

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<HospitalRoom>[], TableLayout[]>([], tableLayout?.currentLayout);
    return tableColumn.map<Column<HospitalRoom>>((item) => {
      if (item.field === 'name') {
        item.render = (hospitalRoom) => <HospitalRoomColumn hospitalRoom={hospitalRoom} />;
      }
      if (item.field === 'floorNumber') {
        item.render = ({isGroundFloor, floorNumber}) => <>{isGroundFloor ? floorNumber : '地下' + floorNumber}階</>;
      }
      if (item.field === 'hospitalWard') {
        item.render = ({hospitalWard}) => hospitalWard?.name;
      }
      if (item.field === 'createdBy') {
        item.render = ({createdBy}) => {
          return UserFormatter.getFullName(createdBy);
        };
      }
      if (item.field === 'showRentalPlace') {
        item.render = ({showRentalPlace}) => {
          return showRentalPlace ? '表示する' : '表示しない';
        };
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout]);

  const handleClickEdit = useCallback(
    async (e: React.MouseEvent, data: HospitalRoom) => {
      e.stopPropagation();
      const hospitalWard = await fetchHospitalWard(data.hospitalHashId, data.hospitalWard?.hashId ?? '');

      const editHospitalRoom = await dialogHandler.open(NewEditationDialog, {
        room: data,
        ward: hospitalWard,
      });

      try {
        await updateHospitalRoom(myInfo.hospitalHashId, data.hashId, editHospitalRoom);
        openSnackBar('大エリアを更新しました。');

        await queryRoom.refetch();
      } catch (_err) {
        openSnackBar('大エリアの更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo, queryRoom]
  );

  const handleClickDelete = useCallback(
    async (e: React.MouseEvent, data: HospitalRoom) => {
      e.stopPropagation();

      await dialogHandler.open(AlertDialog, {
        title: '1件の小エリアを削除しますか？',
        content: '1件の小エリアを削除しようとしています。小エリアを削除すると元に戻せません。',
        positiveButtonLabel: '小エリアを削除',
      });

      try {
        await deleteHospitalRoom(myInfo.hospitalHashId, data.hashId);
        openSnackBar('大エリアを削除しました。');
        await queryRoom.refetch();
      } catch (_err) {
        openSnackBar('大エリアの削除に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo, queryRoom]
  );

  return (
    <>
      <Grid container className={classes.tableContainer}>
        <Grid item className={classes.actionContainer}>
          絞り込み条件：
          <PopperSelectBoxButton
            buttonLabel={'大エリア'}
            options={queryWard.data.map((ward) => ({
              value: ward.hashId,
              label: ward.name,
            }))}
            initialOption={queryWard.data
              .map((ward) => ({
                value: ward.hashId,
                label: ward.name,
              }))
              .filter((i) => wardHashIds.includes(i.value))}
            isMulti={true}
            onChange={(vals) => setWardHashIds(vals?.map((item) => item.value) || [])}
            searchable={true}
          />
        </Grid>
        <div className={templateClasses.flex} />
        <Grid item sm={4} md={4} style={{marginRight: '32px'}}>
          <TextField
            label={'小エリアで検索'}
            variant={'outlined'}
            fullWidth
            size={'small'}
            InputProps={{
              endAdornment: <Search />,
            }}
            className={classes.searchText}
            onChange={(e) => {
              setSearchName(e.target.value);
              handleChangePage(e, 1);
            }}
          />
        </Grid>
        <Button variant={'contained'} color={'primary'} onClick={handleClickRegistration}>
          小エリアを作成
        </Button>
      </Grid>
      <Grid container className={classes.tableContainer}>
        <Table<HospitalRoom>
          columns={serializedTableColumn}
          isLoading={queryRoom.isLoading}
          data={roomData}
          showSelection={true}
          selectionButtons={[
            {label: '大エリアに追加', IconComponent: Add, onClick: handleBulkUpdateRooms},
            {label: '削除', IconComponent: Delete, onClick: handleBulkDeleteRooms},
          ]}
          onRowClick={handleClickEdit}
          rowActions={useMemo(
            () => [
              {
                type: 'button',
                label: '編集',
                onClick: handleClickEdit,
              },
              {
                type: 'button',
                label: '削除',
                onClick: handleClickDelete,
              },
            ],
            [handleClickEdit, handleClickDelete]
          )}
          onOrderChange={handleOrderChange}
        />
      </Grid>
      <Grid container justifyContent={'center'}>
        <Pagination page={page} count={totalPageRoom} color={'primary'} shape="rounded" onChange={handleChangePage} />
      </Grid>
    </>
  );
};
